<template lang="pug">
</template>

<script>
export default {
  name: 'id',

  mounted () {
    const id = this.$route.params.id
    this.$store.dispatch('invitation/setInvitationId', id)
    this.$router.replace('/')
  }
}
</script>

<style>
</style>
